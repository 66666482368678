<template>
  <master-layout bgColor="white">

    <h1>Bejelentkezés</h1>
    <ion-card>
      <ion-card-content>
        <ion-item>
          <ion-label position="stacked">E-mail</ion-label>
          <ion-input type="email" v-model="userInfo.identifier"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Jelszó</ion-label>
          <ion-input type="password" v-model="userInfo.password"></ion-input>
        </ion-item>
        <div class="error-message ion-padding" data-testid="message-area">
          {{ errorMessage }}
        </div>
      </ion-card-content>
    </ion-card>

    <ion-button shape="round" expand="block" color="primary" @click="login()">Bejelentkezem</ion-button>
    <ion-button expand="block" fill="transparent" color="secondary" @click="$router.go(-1)">Mégsem</ion-button>
    <ion-button expand="block" fill="transparent" color="primary" @click="$router.push('/elvesztettjelszo')">Elfelejtett jelszó</ion-button>
    <div class="bottomSpacer"></div>

  </master-layout>
</template>

<script>
import {
  IonCard, IonCardContent, IonItem, IonLabel, IonInput, IonButton, loadingController
} from '@ionic/vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    IonCard, IonCardContent, IonItem, IonLabel, IonInput, IonButton
  },
  data() {
    return {
      userInfo: {
        identifier: '',
        password: ''
      },
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters('auth', {
      loginStatus: 'getLoginStatus'
    }),
    ...mapGetters('wineries', {
      wineryInMemory: 'getWineryInMemory'
    })
  },
  methods: {
    ...mapActions('auth', {
      loginUser: 'loginUser'
    }),
    ...mapActions('wineries', {
      setMyWineriesChanged: 'setMyWineriesChanged',
      setMyWineriesZero: 'setMyWineriesZero'
    }),
    async login() {
      if (this.userInfo.identifier && this.userInfo.password) {
        this.presentLoading()
        await this.loginUser(this.userInfo)
        await this.loading.dismiss()
        if(this.loginStatus === 'success'){
          // reinitialize values
          this.setMyWineriesChanged(true)
          this.setMyWineriesZero(null)
          if(this.wineryInMemory == '' || this.wineryInMemory == null){
            this.$router.push('/profil')
          } else {
            this.$router.push({
              name: 'checkin-confirm',
              params: {
                id: this.wineryInMemory
              }
            })
          }
        }else{
          this.errorMessage = 'Helytelen felhasználónév vagy jelszó.'
        }
      } else {
        this.errorMessage = 'Kérjük, töltsd ki az összes mezőt!'
      }
    },
    async presentLoading() {
      this.loading = await loadingController
        .create({
          spinner: 'lines'
        })
      await this.loading.present()
    }
  }
}
</script>

<style scoped>
ion-button{
  margin: 20px 40px;
}
</style>
